import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { actions as authActions } from '../../store/reducers/authUser';
import {
  actions as registrationActions,
  paymentPlans,
  paymentModes,
} from '../../store/reducers/registration';

import Button from '../../components/button';
import SlideBox from '../../components/slide-box';
import ApplyPromotion from '../../components/on-boarding/promo-code';

import PaymentPlan from '../../components/on-boarding/payment-plan';
import PaymentSummary from '../../components/on-boarding/payment-summary';
import User from '../../models/user';
import PaymentOption from '../../components/on-boarding/payment-option';

import './PaymentMethod.scss';
import { ContainerLoader } from '../../components/loader';
import { State } from '../../store/interfaces';
import { CheckboxInput } from '../../components/form-group/checkbox';
import ApplyReferral from '../../components/on-boarding/referral-apply-box';

interface CustomProps {
  isLoading: boolean;
  hasPromotion: boolean;
  addSummary?: (data: any) => void;
  user?: User;
  gotoNextBlock?: () => any;
  selectFullPayment?: () => any;
  selectStagePayment?: () => any;
  course: any;
  fullPaymentSelected: boolean;
  totalAmount?: number;
  totalForMilestones?: number;
  totalRtaForMilestones?: number;
  totalEdsForMilestones?: number;
  promotion?: any;
  summary: any;
  paymentMethod: paymentModes;
  selectPayAtEDC: () => void;
  selectPayOnline: () => void;
  courseType: string;
  milestones: any[];
  payingAmount: number;
  removePromoCode: () => void;
  completePayment: (
    milestones: any[],
    payingAmount: number,
    courseType: string,
    promotion: any,
    retryPayment: boolean,
    promoCode?: string,
    referralAmount?: number,
    referralList?: any,
    referralCode?: string,
    referralType?:string,
  ) => void;
  OnlinePaymentStatus?: string;
  showPromoCodeBox?: boolean;
  promotions?: any[];
  promoCode?: string;
  classes?: string;
  onApply: (value: any) => void;
  logout: () => void;
  onReferralChange: (value: any) => void,
  hasReferral: boolean,
  onApplyReferral:(value: any,referralType:any) => void,
  onRemoveReferral:(value: any) => void,
  referralList?:any[],
  referralCode?:string,
  referralAmount?:number,
  referralType?:string,
  
}
class PaymentPlans extends Component<CustomProps> {
  static defaultProps: Partial<CustomProps> = {
    summary: {},
    hasReferral:false,
  };

  constructor(props: CustomProps) {
    super(props);
    this.state = {};
    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onApply = this.onApply.bind(this);
   this.onReferralChange = this.onReferralChange.bind(this);
  }

  async onSubmit() {
    document
      ?.getElementById('paymentPlans')
      ?.scrollIntoView({ behavior: 'smooth' });
    try {
      if (this.props.addSummary) {
        this.props.addSummary({
          key: 'paymentplan',
          label: 'Your choose payment plan as',
          value: this.props.fullPaymentSelected
            ? 'Full Payment'
            : 'Stage payment',
        });
        this.props.addSummary({
          key: 'paymableamount',
          label: 'and amount payable is',
          value: `AED ${(this.props.totalAmount || 0).toFixed(2)}`,
        });
      }
      await this.props.completePayment(
        this.props.milestones,
        this.props.payingAmount,
        this.props.courseType,
        this.props.promotion,
        this.props.OnlinePaymentStatus === 'failed',
        this.props.promoCode,
        this.props.referralAmount,
        this.props.referralList,
        this.props.referralCode, 
      );
      if (this.props.gotoNextBlock) this.props.gotoNextBlock();
    } catch (err) {
      if (err.message === 'User has already done payment') {
        toastr.error(
          'Error',
          'User has already done payment. Please login again'
        );
        this.props.logout();
      } else {
        toastr.error('Error', err.response.data.message);
      }
    }
  }

  onChange() {
    this.props.removePromoCode();
  }

  onApply(value: any) {
    this.props.onApply(value);
  }
  onReferralChange(value: any) {
    this.props.onReferralChange(value);
  }


  renderPromotionInput() {
    if (this.props.showPromoCodeBox) {
      return (
        <SlideBox
          showPromoCodeBox={this.props.showPromoCodeBox}
          promotions={this.props.promotions}
          promoCode={this.props.promoCode}
          onApply={this.onApply}
          classes={this.props.classes}
        />
      );
    }
    return (
      <ApplyPromotion
        promotion={this.props.promotion}
        amount={this.props.summary.totalDiscount}
        showRemoveButton={false} // {!this.props.hasPromotion}
        showPromoCodeBox={this.props.showPromoCodeBox}
        onChange={() => this.props.removePromoCode()}
      />
    );
  }

  renderReferralInput() {
    return (
      <div className='mt-3' >
       <ApplyReferral
        promotion={this.props.promotion}
        amount={this.props.summary.totalDiscount}
        showRemoveButton
        showPromoCodeBox={this.props.showPromoCodeBox}
        referralApplyText="Apply"
        course={this.props.course}
        referralCode={this.props.referralCode}
        onClick={(value,referralType) => this.props.onApplyReferral(value,referralType)}
        onRemoveReferral={(value) =>
          this.props.onRemoveReferral(value)
        }
        referralType={this.props.referralType}
      />
      </div>
     
    );
  }



  renderPayOnlineMethod() {
    return (
      <div className="w-full mb-4 sm:mb-0">
        <PaymentOption
          onSelect={() => this.props.selectPayOnline()}
          id="pay-method-online"
          // disabled
          label="Pay Online"
          icon="icon-payonline"
          selected={this.props.paymentMethod === paymentModes.online}
        />
      </div>
    );
  }

  // eslint-disable-next-line class-methods-use-this
  renderPayAtECOMethod() {
    return (
      <div className="w-full">
        <PaymentOption
          onSelect={() => this.props.selectPayAtEDC()}
          id="pay-method-ecodrive"
          label="Pay at Eco Drive"
          icon="ecodrive"
          selected={this.props.paymentMethod === paymentModes.edsCenter}
        />
      </div>
    );
  }

  renderPaymentMethods() {
    return (
      <div className="pt-3 md:pt-8 pb-5">
        <p className="text-base font-semibold pb-1 md:pb-4">
          Choose payment method
        </p>
        <div className="sm:flex py-2 mb-6 sm:mb-8 gap-8">
          {this.renderPayOnlineMethod()}
          {this.renderPayAtECOMethod()}
        </div>
        {/* <div className="card-detail mt-4">
            {this.renderCardInput()}
          </div> */}
      </div>
    );
  }

  renderCompletePaymentButton() {
    const buttonText = this.props.isLoading
      ? 'Please wait'
      : 'Complete Payment';
    return (
      <button
        type="button"
        className="btn btn-primary block w-full col-span-2 md:col-span-1"
        disabled={this.props.isLoading}
        onClick={() => {
          document
            ?.getElementById('paymentPlans')
            ?.scrollIntoView({ behavior: 'smooth' });
          this.props.completePayment(
            this.props.milestones,
            this.props.payingAmount,
            this.props.courseType,
            this.props.promotion,
            this.props.OnlinePaymentStatus === 'failed',
            this.props.promoCode,
            this.props.referralAmount,
            this.props.referralList,
            this.props.referralCode, 
          );
        }}
      >
        {buttonText}
      </button>
    );
  }

  renderContinueToPaymentButton() {
    return (
      <button
        onClick={this.onSubmit}
        disabled={this.props.payingAmount <= 0}
        className="btn btn-primary block w-full col-span-2 md:col-span-1"
      >
        Proceed to Pay
      </button>
    );
  }

  render() {
    if (this.props.isLoading && this.props?.OnlinePaymentStatus) {
      return (
        <ContainerLoader
          height={500}
          text={'Loading payment interface..'}
        ></ContainerLoader>
      );
    }

    if (this.props.isLoading || !this.props.course) {
      return (
        <ContainerLoader
          height={500}
          text={'Loading course structure..'}
        ></ContainerLoader>
      );
    }

    return (
      <div className="pt-5 sm:pt-7 ltr:md:pl-10 rtl:md:pr-10" id="paymentPlans">
        <div className="grid grid-cols-11">
          <div className="col-span-12 md:col-span-9 lg:col-span-7">
            <p className="uppercase text-[13px]">
              Starter plan is right for you.
            </p>
            <div className="">
              <PaymentPlan
                key="full-payment"
                id="full-payment"
                name="Full payment"
                description={
                  this.props.course.amount.totalDiscount > 0
                    ? `You save AED ${this.props.course.amount.totalDiscount}`
                    : ''
                }
                totalAmount={
                  this.props.course.amount.totalAmount -
                  this.props.course.amount.totalDiscount
                }
                totalRtaAmount={this.props.course.amount.rta.totalAmount}
                totalEdsAmount={this.props.course.amount.eds.totalAmount}
                allowPartialPayment={false}
                selected={this.props.fullPaymentSelected}
                onSelect={this.props.selectFullPayment}
                primary
              />
              <PaymentPlan
                id="stage-payment"
                name="Choose what you want to pay"
                key="stage-payment"
                description="You can pay for multiple stages"
                totalAmount={this.props.totalForMilestones}
                totalRtaAmount={this.props.totalRtaForMilestones}
                totalEdsAmount={this.props.totalEdsForMilestones}
                allowPartialPayment
                selected={!this.props.fullPaymentSelected}
                onSelect={this.props.selectStagePayment}
              />
            </div>
            {/* {this.props.hasPromotion && ( */}
            <div className="pt-3">
              <p className="uppercase text-[13px]">Promocodes</p>
              {this.renderPromotionInput()}
            </div>

            <div className="pt-3">
              <CheckboxInput
                key="do-you-have-referral"
                options={[true, 'Do you have any referrals ? ']}
                onChange={(value) =>
                  this.onReferralChange(value)
                } 
                default={this.props.hasReferral} />
            </div>

            {this.props.hasReferral && (
              <div className="">
                <p className=" text-body ">Referral Type *</p>
                {this.renderReferralInput()}
              </div>
            )}

            {/* )} */}
            <PaymentSummary summary={this.props.summary}
              referralAmount={this.props.referralAmount}
            />
            {this.renderPaymentMethods()}
            <div className="grid grid-cols-2 gap-8">
              {this.props.paymentMethod === paymentModes.edsCenter &&
                this.renderCompletePaymentButton()}
              {this.props.paymentMethod === paymentModes.online &&
                this.renderContinueToPaymentButton()}
            </div>
          </div>
        </div>
      </div>
    );
  }
} 

const mapStateToProps = (state: State) => ({
  isLoading: state.registration.isLoading,
  user: state.registration.user,
  course: state.registration.course,
  hasPromotion: state.registration.promotion != null,
  promotion: state.registration.promotion,
  summary: state.registration.summary,
  totalAmount:
    state.registration.summary.totalAmount +
    state.registration.summary.totalTax -
    state.registration.summary.totalDiscount,
  fullPaymentSelected:
    state.registration.paymentPlan === paymentPlans.fullPayment,
  selectedMilestones: state.registration.stagePayment.milestones,
  totalForMilestones:
    state.registration.stagePayment.summary.totalAmount -
    state.registration.stagePayment.summary.totalDiscount,
  totalRtaForMilestones:
    state.registration.stagePayment.summary.eds.totalAmount,
  totalEdsForMilestones:
    state.registration.stagePayment.summary.rta.totalAmount,
  courseType: state.registration.courseType as string,
  paymentMethod: state.registration.modeOfPayment,
  milestones:
    state.registration.course &&
      state.registration.paymentPlan === paymentPlans.fullPayment
      ? state.registration.course.milestones.map((ml: any) => ({ _id: ml._id }))
      : state.registration.stagePayment.milestones.map((ml: any) => ({
        _id: ml._id,
      })),
  payingAmount:
    state.registration.summary.totalAmount +
    state.registration.summary.totalTax -
    state.registration.summary.totalDiscount - 
    (state.registration?.referralAmount || 0),
  OnlinePaymentStatus: state.registration.onlinePaymentStatus,
  showPromoCodeBox: state.registration.showPromoCodeBox,
  classes: state.registration.showPromoCodeBox ? 'slideBox active' : 'slideBox',
  promotions: state.registration.promotions,
  promoCode: state.registration.promoCode,
   hasReferral: state.registration.hasReferral,
  referralAmount:state.registration?.referralAmount || 0,
 referralList: state.registration.referralList,
 referralCode: state.registration.referralCode,
 referralType: state.registration.referralType,
});

const mapDispatchToProps = (dispatch: any) => ({
  selectStagePayment: () =>
    dispatch(registrationActions.changePaymentPlan(paymentPlans.stagePayment)),
  selectFullPayment: () =>
    dispatch(registrationActions.changePaymentPlan(paymentPlans.fullPayment)),
  onMilestoneSelection: (milestone: any, selected: boolean) =>
    dispatch(registrationActions.selectMilestone(milestone, selected)),
  selectPayAtEDC: () =>
    dispatch(registrationActions.changePaymentMethod(paymentModes.edsCenter)),
  selectPayOnline: () =>
    dispatch(registrationActions.changePaymentMethod(paymentModes.online)),
  completePayment: (
    milestones: any[],
    amount: number,
    courseType: string,
    promotion: any,
    retryPayment: boolean,
    promoCode?: string,
    referralAmount?: number,
    referralList?: any,
    referralCode?: string,
  ) =>
    dispatch(
      registrationActions.initiatePayment(
        milestones,
        amount,
        courseType,
        promotion,
        retryPayment,
        promoCode,
        referralAmount,
        referralList,
        referralCode
      )
    ),
  removePromoCode: () => dispatch(registrationActions.clearPromoCode()),
  onApply: (promoCode: string) =>
    dispatch(registrationActions.applyPromoCode(promoCode)),
  logout: () => dispatch(authActions.logout()),

  onReferralChange: (selected: any,) =>
    dispatch(registrationActions.showReferralBox(selected)),

  onApplyReferral: (referral: any,referralType:any) =>
    dispatch(registrationActions.applyReferral(referral,referralType)),
  onRemoveReferral: (referral: any) =>
    dispatch(registrationActions.removeReferral(referral)),
});

// PaymentPlans.propTypes = {
//   isLoading: PropTypes.bool,
//   hasPromotion: PropTypes.bool,
//   user: PropTypes.shape({}),
//   course: PropTypes.shape({
//     amount: PropTypes.shape({
//       totalAmount: PropTypes.number,
//       totalDiscount: PropTypes.number,
//       rta: PropTypes.shape({ totalAmount: PropTypes.number }),
//       eds: PropTypes.shape({ totalAmount: PropTypes.number }),
//       promotions: PropTypes.arrayOf(PropTypes.shape({})),
//     }),
//   }),
//   addSummary: PropTypes.func.isRequired,
//   gotoNextBlock: PropTypes.func.isRequired,
//   fullPaymentSelected: PropTypes.bool.isRequired,
//   totalAmount: PropTypes.number.isRequired,
//   totalForMilestones: PropTypes.number.isRequired,
//   totalRtaForMilestones: PropTypes.number.isRequired,
//   totalEdsForMilestones: PropTypes.number.isRequired,
//   onSubmit: PropTypes.func.isRequired,
//   selectFullPayment: PropTypes.func.isRequired,
//   selectStagePayment: PropTypes.func.isRequired,
//   summary: PropTypes.shape({
//     totalAmount: PropTypes.number,
//     totalTax: PropTypes.number,
//     totalDiscount: PropTypes.number,
//   }),
//   promotion: PropTypes.shape({}),
// };

export default connect(mapStateToProps, mapDispatchToProps)(PaymentPlans);
